// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCU2b_JKsUTwpxM_1lAPk36uJ-ssWwSmLY",
  authDomain: "jefflopez-bass.firebaseapp.com",
  projectId: "jefflopez-bass",
  storageBucket: "jefflopez-bass.appspot.com",
  messagingSenderId: "835284370052",
  appId: "1:835284370052:web:f23d739d1e4f0a8a93b8d1",
  measurementId: "G-XHS0QHLXRP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { app, analytics, auth, db, storage };
