import React, { useState, useCallback, useRef } from 'react';
import { Autocomplete, GoogleMap, Marker } from '@react-google-maps/api';
import { db } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';

const MapDisplay = ({ lat, lng }) => {
  const mapContainerStyle = {
    width: '100%',
    height: '400px'
  };

  const center = {
    lat: parseFloat(lat),
    lng: parseFloat(lng)
  };

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={15}
      center={center}
    >
      <Marker position={center} />
    </GoogleMap>
  );
};

const ManageEvents = () => {
  const [eventName, setEventName] = useState('');
  const [location, setLocation] = useState('');
  const [locationDetails, setLocationDetails] = useState(null);
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [description, setDescription] = useState('');
  const [mapError, setMapError] = useState(null);
  const [submitMessage, setSubmitMessage] = useState(null);
  const [showMap, setShowMap] = useState(false);
  const autocompleteRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitMessage(null);
    
    const eventData = {
      eventName,
      location,
      locationDetails: locationDetails || {},
      date,
      time,
      description,
      createdAt: new Date()
    };

    console.log('Submitting event data:', eventData);

    try {
      const docRef = await addDoc(collection(db, "events"), eventData);
      console.log("Event added with ID: ", docRef.id);
      setSubmitMessage("Event successfully added!");
      
      // Reset form fields
      setEventName('');
      setLocation('');
      setLocationDetails(null);
      setDate('');
      setTime('');
      setDescription('');
      setShowMap(false);
    } catch (error) {
      console.error("Error adding event: ", error);
      setSubmitMessage("Error adding event. Please try again.");
    }
  };

  const handlePlaceSelect = useCallback((place) => {
    console.log('Place selected:', place);
    if (place && place.formatted_address) {
      setLocation(place.formatted_address);
      
      const details = {
        placeId: place.place_id,
        formattedAddress: place.formatted_address,
        lat: place.geometry?.location.lat(),
        lng: place.geometry?.location.lng(),
        name: place.name,
        types: place.types,
      };

      if (place.address_components) {
        details.addressComponents = place.address_components.map(component => ({
          long_name: component.long_name,
          short_name: component.short_name,
          types: component.types,
        }));
      }

      console.log('Setting locationDetails:', details);
      setLocationDetails(details);
    }
  }, []);

  const renderForm = () => (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label htmlFor="eventName" className="block mb-1">Performance Name</label>
        <input
          type="text"
          id="eventName"
          value={eventName}
          onChange={(e) => setEventName(e.target.value)}
          className="w-full px-3 py-2 border rounded"
          required
        />
      </div>
      <div>
        <label htmlFor="location" className="block mb-1">Location</label>
        <Autocomplete
          onLoad={(autocomplete) => {
            console.log('Autocomplete loaded');
            autocompleteRef.current = autocomplete;
          }}
          onPlaceChanged={() => {
            const place = autocompleteRef.current.getPlace();
            console.log('Place changed:', place);
            handlePlaceSelect(place);
          }}
        >
          <input
            type="text"
            id="location"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            className="w-full px-3 py-2 border rounded"
            required
          />
        </Autocomplete>
        {locationDetails && (
          <button
            type="button"
            onClick={() => setShowMap(!showMap)}
            className="button-primary"
          >
            {showMap ? 'Hide Map' : 'Display Map'}
          </button>
        )}
        {showMap && locationDetails && (
          <div className="mt-4">
            <MapDisplay lat={locationDetails.lat} lng={locationDetails.lng} />
          </div>
        )}
      </div>
      <div>
        <label htmlFor="date" className="block mb-1">Date</label>
        <input
          type="date"
          id="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          className="w-full px-3 py-2 border rounded"
          required
        />
      </div>
      <div>
        <label htmlFor="time" className="block mb-1">Time</label>
        <input
          type="time"
          id="time"
          value={time}
          onChange={(e) => setTime(e.target.value)}
          className="w-full px-3 py-2 border rounded"
          required
        />
      </div>
      <div>
        <label htmlFor="description" className="block mb-1">Description</label>
        <textarea
          id="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="w-full px-3 py-2 border rounded"
          rows="4"
          required
        ></textarea>
      </div>
      <button type="submit" className="button-primary">
        Add Event
      </button>
    </form>
  );

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-2xl font-bold mb-4">Manage Events</h2>
      {mapError && <div className="text-yellow-500 mb-4">{mapError}</div>}
      {submitMessage && <div className={`mb-4 ${submitMessage.includes("Error") ? "text-red-500" : "text-green-500"}`}>{submitMessage}</div>}
      {renderForm()}
    </div>
  );
};

export default ManageEvents;
