import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import logo from '../assets/images/orangeOnWhite.png';
import whiteIcon from '../assets/images/whiteFull.png';

function Header() {
  const { user, isAdmin, isStudent } = useAuth();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const renderAuthLinks = () => {
    if (user) {
      return (
        <>
          {isAdmin && (
            <li className="ml-2 lg:ml-4"><Link to="/admin-dashboard" className="button-primary">Admin</Link></li>
          )}
          {isStudent && (
            <li className="ml-2 lg:ml-4"><Link to="/student-dashboard" className="button-primary ">My Lessons</Link></li>
          )}
          <li className="ml-2 lg:ml-4"><button onClick={handleLogout} className="button-negative">Logout</button></li>
        </>
      );
    } else {
      return (
        <>
          <li className="ml-2 lg:ml-4"><Link to="/student-signup" className="button-primary ">Sign Up</Link></li>
          <li className="ml-2 lg:ml-4"><Link to="/student-login" className="button-primary ">Login</Link></li>
        </>
      );
    }
  };

  const renderMobileAuthLinks = () => {
    if (user) {
      return (
        <>
          {isAdmin && (
            <li><Link to="/admin-dashboard" className="button-secondary" onClick={closeMenu}>Admin</Link></li>
          )}
          {isStudent && (
            <li><Link to="/student-dashboard" className="button-secondary" onClick={closeMenu}>My Lessons</Link></li>
          )}
          <li><button onClick={() => { handleLogout(); closeMenu(); }} className="button-negative">Logout</button></li>
        </>
      );
    } else {
      return (
        <>
          <li><Link to="/student-signup" className="button-secondary" onClick={closeMenu}>Sign Up</Link></li>
          <li><Link to="/student-login" className="button-secondary" onClick={closeMenu}>Login</Link></li>
        </>
      );
    }
  };

  return (
    <header className="py-2 md:py-4 bg-white shadow-md relative z-50">
      <div className="container mx-auto px-4 md:px-6 lg:px-8 max-w-7xl">
        <div className="flex justify-between items-center">
          <Link to="/" className="flex-shrink-0">
            <img src={logo} alt="Jeff Lopez Logo" className="w-32 md:w-48 lg:w-64" />
          </Link>
          <button
            className="md:hidden text-gray-600 focus:outline-none z-50"
            onClick={toggleMenu}
          >
            <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
              {isMenuOpen ? (
                <path fillRule="evenodd" clipRule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z" />
              ) : (
                <path fillRule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 4h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 4h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 4h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
              )}
            </svg>
          </button>
          <nav className="hidden md:block flex-grow justify-end">
            <ul className="flex items-center justify-end md:space-x-2 lg:space-x-4 xl:space-x-6">
              <li><Link to="/" className="text-sm lg:text-base text-gray-800 hover:text-primary whitespace-nowrap px-1">Home</Link></li>
              <li><Link to="/music" className="text-sm lg:text-base text-gray-800 hover:text-primary whitespace-nowrap px-1">Music</Link></li>
              <li><Link to="/free-lessons" className="text-sm lg:text-base text-gray-800 hover:text-primary whitespace-nowrap px-1">Free Lessons</Link></li>
              <li><Link to="/events" className="text-sm lg:text-base text-gray-800 hover:text-primary whitespace-nowrap px-1">Events</Link></li>
              <li><Link to="/links" className="text-sm lg:text-base text-gray-800 hover:text-primary whitespace-nowrap px-1">Links</Link></li>
              <li><Link to="/contact" className="text-sm lg:text-base text-gray-800 hover:text-primary whitespace-nowrap px-1">Contact</Link></li>
              {renderAuthLinks()}
            </ul>
          </nav>
        </div>
      </div>
      {/* Full-page overlay menu */}
      <div className={`fixed inset-0 bg-primary bg-opacity-90 backdrop-blur-sm z-40 transition-all duration-300 ease-in-out ${isMenuOpen ? 'translate-y-0' : '-translate-y-full'}`}>
        <div className="absolute top-0 left-0 right-0 flex justify-center pt-8">
          <img src={whiteIcon} alt="Jeff Lopez White Icon" className="w-12 md:w-24" />
        </div>
        <nav className="h-full flex items-center justify-center">
          <ul className="space-y-6 text-center">
            <li><Link to="/" className="text-white hover:text-black" onClick={closeMenu}>Home</Link></li>
            <li><Link to="/music" className="text-white hover:text-black" onClick={closeMenu}>Music</Link></li>
            <li><Link to="/free-lessons" className="text-white hover:text-black" onClick={closeMenu}>Free Lessons</Link></li>
            <li><Link to="/events" className="text-white hover:text-black" onClick={closeMenu}>Events</Link></li>
            <li><Link to="/links" className="text-white hover:text-black" onClick={closeMenu}>Links</Link></li>
            <li><Link to="/contact" className="text-white hover:text-black" onClick={closeMenu}>Contact</Link></li>
            {renderMobileAuthLinks()}
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
