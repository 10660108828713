import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import { FaFileAlt, FaHeadphones, FaVideo } from 'react-icons/fa';

const FreeLessons = () => {
  const [lessons, setLessons] = useState([]);

  useEffect(() => {
    const fetchLessons = async () => {
      const lessonsCollection = collection(db, 'lessons');
      const freeLessonsQuery = query(lessonsCollection, where("isFree", "==", true));
      const lessonsSnapshot = await getDocs(freeLessonsQuery);
      const lessonsList = lessonsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setLessons(lessonsList);
    };

    fetchLessons();
  }, []);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div className="free-lessons min-h-screen py-8 px-4 sm:py-12 sm:px-6 lg:px-8">
      <div className="max-w-6xl mx-auto">
        <h1 className="text-3xl font-extrabold text-gray-900 text-center mb-8">Free Lessons</h1>
        {lessons.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {lessons.map(lesson => (
              <Link to={`/lesson/${lesson.id}`} key={lesson.id} className="bg-white shadow-md rounded-lg p-6 hover:shadow-lg transition-shadow duration-300">
                <h2 className="text-xl font-semibold mb-2">{lesson.name}</h2>
                <p className="text-gray-600 mb-4">Difficulty: {capitalizeFirstLetter(lesson.difficulty)}</p>
                <div className="flex gap-4 text-gray-500">
                  {lesson.pdfUrl && (
                    <div className="flex items-center justify-center w-8 h-8 rounded-full hover:bg-gray-100 transition-colors">
                      <FaFileAlt 
                        className="text-2xl hover:text-primary transition-colors" 
                        title="This lesson includes a PDF"
                      />
                    </div>
                  )}
                  {lesson.mp3Url && (
                    <div className="flex items-center justify-center w-8 h-8 rounded-full hover:bg-gray-100 transition-colors">
                      <FaHeadphones 
                        className="text-2xl hover:text-primary transition-colors" 
                        title="This lesson includes audio"
                      />
                    </div>
                  )}
                  {lesson.videoUrl && (
                    <div className="flex items-center justify-center w-8 h-8 rounded-full hover:bg-gray-100 transition-colors">
                      <FaVideo 
                        className="text-2xl hover:text-primary transition-colors" 
                        title="This lesson includes video"
                      />
                    </div>
                  )}
                </div>
              </Link>
            ))}
          </div>
        ) : (
          <p className="text-center text-gray-600">No free lessons available at the moment. Check back later!</p>
        )}
      </div>
    </div>
  );
};

export default FreeLessons;
