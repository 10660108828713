import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import useGoogleMapsApi from './hooks/useGoogleMapsApi';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Music from './components/Music';
import FreeLessons from './components/FreeLessons';
import LessonDetail from './components/LessonDetail';
import Events from './components/Events';
import Links from './components/Links';
import Contact from './components/Contact';
import StudentLogin from './components/StudentLogin';
import StudentSignup from './components/StudentSignup';
import AdminDashboard from './components/AdminDashboard';
import StudentDashboard from './components/StudentDashboard';
import ManageLessons from './components/ManageLessons';
import ManageEvents from './components/ManageEvents';
import ManageReleases from './components/ManageReleases';
import ManageLinks from './components/ManageLinks';
import ManageContacts from './components/ManageContacts';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Protected Route Components
const AdminRoute = ({ children }) => {
  const { user, isAdmin } = useAuth();
  return user && isAdmin ? children : <Navigate to="/student-login" />;
};

const StudentRoute = ({ children }) => {
  const { user, isStudent } = useAuth();
  return user && isStudent ? children : <Navigate to="/student-login" />;
};

function App() {
  const isGoogleMapsLoaded = useGoogleMapsApi();

  if (!isGoogleMapsLoaded) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-100">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 mx-auto mb-4"></div>
          <p className="text-xl font-semibold">Loading Google Maps...</p>
        </div>
      </div>
    );
  }

  return (
    <AuthProvider>
      <div className="App flex flex-col min-h-screen">
        <Header />
        <main className="flex-grow px-4 py-6 sm:px-6 sm:py-8 md:px-8">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/music" element={<Music />} />
            <Route path="/free-lessons" element={<FreeLessons />} />
            <Route path="/lesson/:lessonId" element={<LessonDetail />} />
            <Route path="/events" element={<Events />} />
            <Route path="/links" element={<Links />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/student-login" element={<StudentLogin />} />
            <Route path="/student-signup" element={<StudentSignup />} />
            
            {/* Protected Admin Routes */}
            <Route
              path="/admin-dashboard"
              element={
                <AdminRoute>
                  <AdminDashboard />
                </AdminRoute>
              }
            >
              <Route path="upload-lessons" element={<ManageLessons />} />
              <Route path="manage-events" element={<ManageEvents />} />
              <Route path="manage-releases" element={<ManageReleases />} />
              <Route path="manage-links" element={<ManageLinks />} />
              <Route path="manage-contacts" element={<ManageContacts />} />
            </Route>

            {/* Protected Student Routes */}
            <Route
              path="/student-dashboard"
              element={
                <StudentRoute>
                  <StudentDashboard />
                </StudentRoute>
              }
            />
          </Routes>
        </main>
        <Footer />
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </AuthProvider>
  );
}

export default App;
