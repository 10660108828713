import React from 'react';
import orangeOnBlack from '../assets/images/orangeOnBlack.png';

const Footer = () => {
  return (
    <footer className="bg-black text-white py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-col items-center space-y-6 md:flex-row md:justify-between md:items-center md:space-y-0">
          <div className="text-center md:text-left">
            <img src={orangeOnBlack} alt="Jeff Lopez" className="h-16 md:h-20 inline-block" />
          </div>
          <div className="text-center">
            <p className="text-sm">&copy; {new Date().getFullYear()} Jeff Lopez. All rights reserved.</p>
          </div>
          <div className="flex justify-center space-x-4 md:justify-end">
            <a href="#" className="text-white hover:text-gray-300 text-2xl md:text-base p-2 md:p-0">
              <i className="fab fa-facebook"></i>
              <span className="sr-only">Facebook</span>
            </a>
            <a href="#" className="text-white hover:text-gray-300 text-2xl md:text-base p-2 md:p-0">
              <i className="fab fa-instagram"></i>
              <span className="sr-only">Instagram</span>
            </a>
            <a href="#" className="text-white hover:text-gray-300 text-2xl md:text-base p-2 md:p-0">
              <i className="fab fa-youtube"></i>
              <span className="sr-only">YouTube</span>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
