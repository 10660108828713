import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { Link } from 'react-router-dom';

const StudentDashboard = () => {
  const [lessons, setLessons] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState('all'); // all, beginner, intermediate, advanced

  useEffect(() => {
    fetchLessons();
  }, []);

  const fetchLessons = async () => {
    try {
      const lessonsQuery = query(collection(db, 'lessons'), orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(lessonsQuery);
      const lessonsList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate()
      }));
      setLessons(lessonsList);
    } catch (error) {
      console.error('Error fetching lessons:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const filteredLessons = lessons.filter(lesson => {
    if (filter === 'all') return true;
    return lesson.difficulty === filter;
  });

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-8">
        <h1 className="text-3xl font-bold mb-4">My Lessons</h1>
        <div className="grid grid-cols-2 sm:flex sm:flex-wrap gap-2 sm:gap-3">
          <button
            onClick={() => setFilter('all')}
            className={`w-full sm:w-auto px-4 py-2 rounded text-sm sm:text-base transition-colors duration-200 ${
              filter === 'all'
                ? 'bg-primary text-white'
                : 'bg-gray-200 hover:bg-gray-300'
            }`}
          >
            All
          </button>
          <button
            onClick={() => setFilter('beginner')}
            className={`w-full sm:w-auto px-4 py-2 rounded text-sm sm:text-base transition-colors duration-200 ${
              filter === 'beginner'
                ? 'bg-primary text-white'
                : 'bg-gray-200 hover:bg-gray-300'
            }`}
          >
            Beginner
          </button>
          <button
            onClick={() => setFilter('intermediate')}
            className={`w-full sm:w-auto px-4 py-2 rounded text-sm sm:text-base transition-colors duration-200 ${
              filter === 'intermediate'
                ? 'bg-primary text-white'
                : 'bg-gray-200 hover:bg-gray-300'
            }`}
          >
            Intermediate
          </button>
          <button
            onClick={() => setFilter('advanced')}
            className={`w-full sm:w-auto px-4 py-2 rounded text-sm sm:text-base transition-colors duration-200 ${
              filter === 'advanced'
                ? 'bg-primary text-white'
                : 'bg-gray-200 hover:bg-gray-300'
            }`}
          >
            Advanced
          </button>
        </div>
      </div>

      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredLessons.map((lesson) => (
            <div
              key={lesson.id}
              className="border rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300"
            >
              <div className="p-6">
                <h3 className="text-xl font-semibold mb-2">{lesson.name}</h3>
                <div className="flex items-center space-x-2 mb-4">
                  <span className={`px-2 py-1 rounded text-sm ${
                    lesson.difficulty === 'beginner' ? 'bg-green-100 text-green-800' :
                    lesson.difficulty === 'intermediate' ? 'bg-yellow-100 text-yellow-800' :
                    'bg-red-100 text-red-800'
                  }`}>
                    {lesson.difficulty.charAt(0).toUpperCase() + lesson.difficulty.slice(1)}
                  </span>
                  {lesson.isFree && (
                    <span className="px-2 py-1 rounded text-sm bg-blue-100 text-blue-800">
                      Free
                    </span>
                  )}
                </div>
                <div className="space-y-2">
                  {lesson.pdfUrl && (
                    <a
                      href={lesson.pdfUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center text-primary hover:text-primary-dark"
                    >
                      <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M9 2a2 2 0 00-2 2v8a2 2 0 002 2h6a2 2 0 002-2V6.414A2 2 0 0015.414 4L13 1.586A2 2 0 0011.586 1H9a2 2 0 00-2 2z" />
                      </svg>
                      Sheet Music (PDF)
                    </a>
                  )}
                  {lesson.mp3Url && (
                    <div className="mt-2">
                      <h4 className="text-sm font-medium mb-1">Audio Track:</h4>
                      <audio controls className="w-full">
                        <source src={lesson.mp3Url} type="audio/mpeg" />
                        Your browser does not support the audio element.
                      </audio>
                    </div>
                  )}
                  {lesson.videoUrl && (
                    <Link
                      to={`/lesson/${lesson.id}`}
                      className="inline-block mt-4 px-4 py-2 bg-primary text-white rounded hover:bg-primary-dark transition-colors duration-300"
                    >
                      Watch Video Lesson
                    </Link>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default StudentDashboard;
