import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

const LessonDetail = () => {
  const [lesson, setLesson] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { lessonId } = useParams();
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    const fetchLesson = async () => {
      try {
        const lessonDoc = doc(db, 'lessons', lessonId);
        const lessonSnapshot = await getDoc(lessonDoc);
        if (lessonSnapshot.exists()) {
          const lessonData = { id: lessonSnapshot.id, ...lessonSnapshot.data() };
          setLesson(lessonData);
          console.log('Lesson data:', lessonData);
        } else {
          setError('Lesson not found');
          console.log('No such lesson!');
        }
      } catch (err) {
        setError('Error fetching lesson');
        console.error('Error fetching lesson:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchLesson();
  }, [lessonId]);

  useEffect(() => {
    if (lesson && lesson.videoUrl) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      const player = videojs(videoElement, {
        controls: true,
        responsive: true,
        fluid: true,
        sources: [{ src: lesson.videoUrl, type: 'video/mp4' }]
      });

      playerRef.current = player;

      return () => {
        if (playerRef.current) {
          playerRef.current.dispose();
        }
      };
    }
  }, [lesson]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="text-red-500">{error}</div>;
  if (!lesson) return <div>No lesson data available</div>;

  return (
    <div className="lesson-detail min-h-screen py-8 px-4 sm:py-12 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-extrabold text-gray-900 mb-8">{lesson.name}</h1>
        <div className="bg-white shadow-md rounded-lg p-6">
          <p className="text-gray-600 mb-4">Difficulty: {lesson.difficulty}</p>
          <p className="text-gray-600 mb-4">{lesson.isFree ? 'Free Lesson' : 'Premium Lesson'}</p>
          {lesson.videoUrl && (
            <div className="mb-6">
              <h2 className="text-xl font-semibold mb-2">Video Lesson</h2>
              <div data-vjs-player>
                <video ref={videoRef} className="video-js vjs-big-play-centered" />
              </div>
            </div>
          )}
          {lesson.mp3Url && (
            <div className="mb-6">
              <h2 className="text-xl font-semibold mb-2">Audio Lesson</h2>
              <audio controls className="w-full">
                <source src={lesson.mp3Url} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            </div>
          )}
          {lesson.pdfUrl && (
            <div className="mb-6">
              <h2 className="text-xl font-semibold mb-2">Lesson PDF</h2>
              <a href={lesson.pdfUrl} target="_blank" rel="noopener noreferrer" className="button-primary">
                Download PDF
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LessonDetail;
