import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, query, orderBy, onSnapshot, doc, updateDoc, deleteDoc } from 'firebase/firestore';

function ManageContacts() {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedContact, setExpandedContact] = useState(null);

  useEffect(() => {
    const q = query(collection(db, 'contacts'), orderBy('timestamp', 'desc'));
    
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const contactsData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp?.toDate().toLocaleString() || 'No date'
      }));
      setContacts(contactsData);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleStatusUpdate = async (contactId, newStatus) => {
    try {
      await updateDoc(doc(db, 'contacts', contactId), {
        status: newStatus
      });
    } catch (error) {
      console.error('Error updating contact status:', error);
    }
  };

  const handleDelete = async (contactId) => {
    if (window.confirm('Are you sure you want to delete this contact?')) {
      try {
        await deleteDoc(doc(db, 'contacts', contactId));
      } catch (error) {
        console.error('Error deleting contact:', error);
      }
    }
  };

  const toggleExpand = (contactId) => {
    setExpandedContact(expandedContact === contactId ? null : contactId);
  };

  if (loading) {
    return <div className="p-4">Loading contacts...</div>;
  }

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Manage Contacts</h2>
      <div className="space-y-4">
        {contacts.map((contact) => (
          <div key={contact.id} className="bg-white rounded-lg shadow-md overflow-hidden">
            <div 
              className="p-4 cursor-pointer hover:bg-gray-50 flex justify-between items-center"
              onClick={() => toggleExpand(contact.id)}
            >
              <div className="flex-1">
                <div className="flex justify-between items-center mb-2">
                  <h3 className="text-lg font-semibold">{contact.name}</h3>
                  <span className="text-sm text-gray-500">{contact.timestamp}</span>
                </div>
                <div className="flex gap-4 text-sm text-gray-600">
                  <span>{contact.email}</span>
                  {contact.phone && <span>• {contact.phone}</span>}
                  <span>• {contact.inquiryType}</span>
                </div>
              </div>
              <svg 
                className={`w-6 h-6 transform transition-transform ${expandedContact === contact.id ? 'rotate-180' : ''}`} 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
              </svg>
            </div>

            {expandedContact === contact.id && (
              <div className="p-4 border-t border-gray-200 bg-gray-50">
                <div className="space-y-4">
                  <div>
                    <h4 className="font-medium text-gray-700 mb-2">Message:</h4>
                    <p className="text-gray-600 whitespace-pre-wrap">{contact.message}</p>
                  </div>

                  {contact.inquiryType === 'privateLessons' && (
                    <>
                      {contact.lessonExperience && (
                        <div>
                          <h4 className="font-medium text-gray-700 mb-2">Bass Playing Experience:</h4>
                          <p className="text-gray-600">{contact.lessonExperience}</p>
                        </div>
                      )}
                      {contact.preferredDay && (
                        <div>
                          <h4 className="font-medium text-gray-700 mb-2">Preferred Day:</h4>
                          <p className="text-gray-600">{contact.preferredDay}</p>
                        </div>
                      )}
                      {contact.preferredTime && (
                        <div>
                          <h4 className="font-medium text-gray-700 mb-2">Preferred Time:</h4>
                          <p className="text-gray-600">{contact.preferredTime}</p>
                        </div>
                      )}
                    </>
                  )}

                  <div className="flex items-center justify-between pt-4">
                    <div className="flex items-center space-x-4">
                      <select
                        value={contact.status}
                        onChange={(e) => handleStatusUpdate(contact.id, e.target.value)}
                        className="border rounded p-2 text-sm"
                      >
                        <option value="new">New</option>
                        <option value="in-progress">In Progress</option>
                        <option value="completed">Completed</option>
                      </select>
                      <a 
                        href={`mailto:${contact.email}`} 
                        className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                      >
                        Reply via Email
                      </a>
                    </div>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(contact.id);
                      }}
                      className="text-red-600 hover:text-red-800 text-sm font-medium"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default ManageContacts;
