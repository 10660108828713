import React, { useState, useEffect } from 'react';
import { storage, db } from '../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import { toast } from 'react-toastify';

const ManageReleases = () => {
  const [releases, setReleases] = useState([]);
  const [albumName, setAlbumName] = useState('');
  const [releaseYear, setReleaseYear] = useState('');
  const [appleMusicUrl, setAppleMusicUrl] = useState('');
  const [spotifyUrl, setSpotifyUrl] = useState('');
  const [albumArtwork, setAlbumArtwork] = useState(null);

  useEffect(() => {
    fetchReleases();
  }, []);

  const fetchReleases = async () => {
    const releasesCollection = collection(db, 'releases');
    const releasesSnapshot = await getDocs(releasesCollection);
    const releasesList = releasesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setReleases(releasesList);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!albumArtwork) {
      toast.error('Please select an album artwork');
      return;
    }

    try {
      // Upload image to Firebase Storage
      const artworkRef = ref(storage, `album-artworks/${albumArtwork.name}`);
      await uploadBytes(artworkRef, albumArtwork);
      const artworkUrl = await getDownloadURL(artworkRef);

      // Add release to Firestore
      const releaseData = {
        name: albumName,
        year: releaseYear,
        appleMusicUrl,
        spotifyUrl,
        artworkUrl
      };

      await addDoc(collection(db, 'releases'), releaseData);

      // Reset form and refresh releases
      setAlbumName('');
      setReleaseYear('');
      setAppleMusicUrl('');
      setSpotifyUrl('');
      setAlbumArtwork(null);
      fetchReleases();

      toast.success('Release added successfully!');
    } catch (error) {
      console.error('Error adding release:', error);
      toast.error('Error adding release. Please try again.');
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-2xl font-bold mb-4">Manage Releases</h2>
      
      <form onSubmit={handleSubmit} className="mb-8">
        <div className="mb-4">
          <label htmlFor="albumName" className="block mb-2">Album Name</label>
          <input
            type="text"
            id="albumName"
            value={albumName}
            onChange={(e) => setAlbumName(e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="releaseYear" className="block mb-2">Release Year</label>
          <input
            type="number"
            id="releaseYear"
            value={releaseYear}
            onChange={(e) => setReleaseYear(e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="appleMusicUrl" className="block mb-2">Apple Music URL</label>
          <input
            type="url"
            id="appleMusicUrl"
            value={appleMusicUrl}
            onChange={(e) => setAppleMusicUrl(e.target.value)}
            className="w-full p-2 border rounded"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="spotifyUrl" className="block mb-2">Spotify URL</label>
          <input
            type="url"
            id="spotifyUrl"
            value={spotifyUrl}
            onChange={(e) => setSpotifyUrl(e.target.value)}
            className="w-full p-2 border rounded"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="albumArtwork" className="block mb-2">Album Artwork</label>
          <input
            type="file"
            id="albumArtwork"
            onChange={(e) => setAlbumArtwork(e.target.files[0])}
            className="w-full p-2 border rounded"
            accept="image/*"
            required
          />
        </div>

        <button type="submit" className="button-primary">
          Add Release
        </button>
      </form>

      <h3 className="text-xl font-bold mb-4">Current Releases</h3>
      <ul>
        {releases.map((release) => (
          <li key={release.id} className="mb-2">
            {release.name} ({release.year})
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ManageReleases;
