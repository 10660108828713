import React, { useState, useEffect } from 'react';
import { db, storage } from '../firebase';
import { 
  collection, 
  addDoc, 
  getDocs, 
  deleteDoc, 
  doc, 
  updateDoc,
  query,
  orderBy 
} from 'firebase/firestore';
import { 
  ref, 
  uploadBytes, 
  getDownloadURL, 
  deleteObject 
} from 'firebase/storage';
import { toast } from 'react-toastify';

const ManageLessons = () => {
  // Upload states
  const [lessonName, setLessonName] = useState('');
  const [difficulty, setDifficulty] = useState('');
  const [isFree, setIsFree] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  const [mp3File, setMp3File] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [showUploadForm, setShowUploadForm] = useState(false);

  // Manage states
  const [lessons, setLessons] = useState([]);
  const [editingLesson, setEditingLesson] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchLessons();
  }, []);

  const fetchLessons = async () => {
    try {
      const lessonsQuery = query(collection(db, 'lessons'), orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(lessonsQuery);
      const lessonsList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate()
      }));
      setLessons(lessonsList);
    } catch (error) {
      console.error('Error fetching lessons:', error);
      toast.error('Error fetching lessons. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileChange = (e, setFile) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const uploadFile = async (file, path) => {
    if (!file) return null;
    const storageRef = ref(storage, `${path}/${file.name}`);
    await uploadBytes(storageRef, file);
    return await getDownloadURL(storageRef);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsUploading(true);

    try {
      const pdfUrl = await uploadFile(pdfFile, 'pdfs');
      const mp3Url = await uploadFile(mp3File, 'mp3s');
      const videoUrl = await uploadFile(videoFile, 'videos');

      await addDoc(collection(db, 'lessons'), {
        name: lessonName,
        difficulty,
        isFree,
        pdfUrl,
        mp3Url,
        videoUrl,
        createdAt: new Date(),
      });

      // Reset form
      setLessonName('');
      setDifficulty('');
      setIsFree(false);
      setPdfFile(null);
      setMp3File(null);
      setVideoFile(null);

      fetchLessons(); // Refresh the list
      toast.success('Lesson uploaded successfully!');
    } catch (error) {
      console.error('Error uploading lesson:', error);
      toast.error('Error uploading lesson. Please try again.');
    }

    setIsUploading(false);
  };

  const handleDelete = async (lesson) => {
    if (window.confirm('Are you sure you want to delete this lesson?')) {
      try {
        // Delete the document from Firestore
        await deleteDoc(doc(db, 'lessons', lesson.id));

        // Delete associated files from Storage
        if (lesson.pdfUrl) {
          const pdfRef = ref(storage, lesson.pdfUrl);
          await deleteObject(pdfRef).catch(error => console.log('Error deleting PDF:', error));
        }
        if (lesson.mp3Url) {
          const mp3Ref = ref(storage, lesson.mp3Url);
          await deleteObject(mp3Ref).catch(error => console.log('Error deleting MP3:', error));
        }
        if (lesson.videoUrl) {
          const videoRef = ref(storage, lesson.videoUrl);
          await deleteObject(videoRef).catch(error => console.log('Error deleting video:', error));
        }

        fetchLessons(); // Refresh the list
        toast.success('Lesson deleted successfully!');
      } catch (error) {
        console.error('Error deleting lesson:', error);
        toast.error('Error deleting lesson. Please try again.');
      }
    }
  };

  const handleEdit = async (lesson) => {
    if (editingLesson?.id === lesson.id) {
      try {
        await updateDoc(doc(db, 'lessons', lesson.id), {
          name: lessonName,
          difficulty,
          isFree
        });

        setEditingLesson(null);
        fetchLessons();
        toast.success('Lesson updated successfully!');
      } catch (error) {
        console.error('Error updating lesson:', error);
        toast.error('Error updating lesson. Please try again.');
      }
    } else {
      setEditingLesson(lesson);
      setLessonName(lesson.name);
      setDifficulty(lesson.difficulty);
      setIsFree(lesson.isFree);
    }
  };

  const filteredLessons = lessons.filter(lesson => 
    lesson.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    lesson.difficulty.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="space-y-8">
        {/* Upload Section */}
        <div>
          <div className="flex flex-col justify-between items-center mb-4">
            <h2 className="text-2xl font-bold">Upload New Lesson</h2>
            <button 
              onClick={() => setShowUploadForm(!showUploadForm)}
              className="button-primary"
            >
              {showUploadForm ? 'Hide Upload Form' : 'Upload new lesson'}
            </button>
          </div>
          
          {showUploadForm && (
            <form onSubmit={handleSubmit} className="space-y-4 bg-gray-50 p-6 rounded-lg">
              <div>
                <label htmlFor="lessonName" className="block mb-1">Lesson Name</label>
                <input
                  type="text"
                  id="lessonName"
                  value={lessonName}
                  onChange={(e) => setLessonName(e.target.value)}
                  required
                  className="w-full px-3 py-2 border rounded"
                />
              </div>
              <div>
                <label htmlFor="difficulty" className="block mb-1">Difficulty</label>
                <select
                  id="difficulty"
                  value={difficulty}
                  onChange={(e) => setDifficulty(e.target.value)}
                  required
                  className="w-full px-3 py-2 border rounded"
                >
                  <option value="">Select Difficulty</option>
                  <option value="beginner">Beginner</option>
                  <option value="intermediate">Intermediate</option>
                  <option value="advanced">Advanced</option>
                </select>
              </div>
              <div>
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={isFree}
                    onChange={(e) => setIsFree(e.target.checked)}
                    className="mr-2"
                  />
                  <span>Free Lesson</span>
                </label>
              </div>
              <div>
                <label htmlFor="pdfFile" className="block mb-1">PDF File</label>
                <input
                  type="file"
                  id="pdfFile"
                  accept=".pdf"
                  onChange={(e) => handleFileChange(e, setPdfFile)}
                  className="w-full"
                />
              </div>
              <div>
                <label htmlFor="mp3File" className="block mb-1">MP3 File</label>
                <input
                  type="file"
                  id="mp3File"
                  accept=".mp3"
                  onChange={(e) => handleFileChange(e, setMp3File)}
                  className="w-full"
                />
              </div>
              <div>
                <label htmlFor="videoFile" className="block mb-1">Video File</label>
                <input
                  type="file"
                  id="videoFile"
                  accept="video/*"
                  onChange={(e) => handleFileChange(e, setVideoFile)}
                  className="w-full"
                />
              </div>
              <button
                type="submit"
                disabled={isUploading}
                className="button-primary"
              >
                {isUploading ? 'Uploading...' : 'Upload Lesson'}
              </button>
            </form>
          )}
        </div>

        {/* Lessons List */}
        <div>
          <div className="mb-6">
            <h2 className="text-2xl font-bold mb-4">Manage Existing Lessons</h2>
            <input
              type="text"
              placeholder="Search lessons by name or difficulty..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          
          {isLoading ? (
            <p>Loading lessons...</p>
          ) : (
            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
              {filteredLessons.map((lesson) => (
                <div key={lesson.id} className="border rounded-lg p-4 bg-white shadow-sm">
                  {editingLesson?.id === lesson.id ? (
                    <div className="space-y-2">
                      <input
                        type="text"
                        value={lessonName}
                        onChange={(e) => setLessonName(e.target.value)}
                        className="w-full px-3 py-2 border rounded"
                      />
                      <select
                        value={difficulty}
                        onChange={(e) => setDifficulty(e.target.value)}
                        className="w-full px-3 py-2 border rounded"
                      >
                        <option value="beginner">Beginner</option>
                        <option value="intermediate">Intermediate</option>
                        <option value="advanced">Advanced</option>
                      </select>
                      <label className="flex items-center">
                        <input
                          type="checkbox"
                          checked={isFree}
                          onChange={(e) => setIsFree(e.target.checked)}
                          className="mr-2"
                        />
                        <span>Free Lesson</span>
                      </label>
                    </div>
                  ) : (
                    <div>
                      <h3 className="text-xl font-semibold">{lesson.name}</h3>
                      <p className="capitalize">Difficulty: {lesson.difficulty}</p>
                      <p>Free: {lesson.isFree ? 'Yes' : 'No'}</p>
                      <p className="text-sm text-gray-500">
                        Created: {lesson.createdAt?.toLocaleDateString()}
                      </p>
                    </div>
                  )}
                  <div className="mt-4">
                    <button
                      onClick={() => handleEdit(lesson)}
                      className="button-primary mb-2"
                    >
                      {editingLesson?.id === lesson.id ? 'Save' : 'Edit'}
                    </button>
                    {editingLesson?.id === lesson.id && (
                      <button
                        onClick={() => setEditingLesson(null)}
                        className="button-negative"
                      >
                        Cancel
                      </button>
                    )}
                    <button
                      onClick={() => handleDelete(lesson)}
                      className="button-negative"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageLessons;
