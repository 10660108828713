import React, { useState } from 'react';
import { useNavigate, Link, Outlet } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="flex flex-col h-screen ">
      {/* Header */}
      <header className="bg-white shadow-md py-4 px-6 flex justify-between items-center md:hidden">
        <h1 className="text-xl font-semibold text-gray-800">Admin Dashboard</h1>
        <button onClick={toggleMenu} className="text-gray-500 hover:text-gray-600 focus:outline-none focus:text-gray-600">
          <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
            {isMenuOpen ? (
              <path fillRule="evenodd" clipRule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z" />
            ) : (
              <path fillRule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
            )}
          </svg>
        </button>
      </header>

      <div className="flex flex-1 overflow-hidden">
        {/* Sidebar */}
        <aside className={` w-64 p-6 flex flex-col ${isMenuOpen ? 'block' : 'hidden'} md:block`}>
          <nav className="flex-1 space-y-4">
            <Link to="upload-lessons" className="block py-2 px-4 rounded transition duration-200 hover:bg-primary hover:text-white text-gray-700">
              Manage Lessons
            </Link>
            <Link to="manage-events" className="block py-2 px-4 rounded transition duration-200 hover:bg-primary hover:text-white text-gray-700">
              Manage Events
            </Link>
            <Link to="manage-releases" className="block py-2 px-4 rounded transition duration-200 hover:bg-primary hover:text-white text-gray-700">
              Manage Releases
            </Link>
            <Link to="manage-links" className="block py-2 px-4 rounded transition duration-200 hover:bg-primary hover:text-white text-gray-700">
              Manage Links
            </Link>
            <Link to="manage-contacts" className="block py-2 px-4 rounded transition duration-200 hover:bg-primary hover:text-white text-gray-700">
              Manage Contacts
            </Link>
          </nav>
          <button
            onClick={handleLogout}
            className="button-negative mt-4"
          >
            Logout
          </button>
        </aside>

        {/* Main content */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 p-6">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default AdminDashboard;
