import React, { createContext, useState, useEffect, useContext } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../firebase';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    let unsubscribeUser = () => {};
    let unsubscribeRole = () => {};

    // Listen for auth state changes
    unsubscribeUser = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        
        // Set up real-time listener for user role
        const userDocRef = doc(db, 'users', user.uid);
        unsubscribeRole = onSnapshot(userDocRef, 
          (doc) => {
            if (doc.exists()) {
              const role = doc.data().role;
              setUserRole(role);
              setLoading(false);
            } else {
              console.error('No user document found for:', user.uid);
              setUserRole(null);
              setLoading(false);
            }
          },
          (error) => {
            console.error('Error fetching user role:', error);
            setUserRole(null);
            setLoading(false);
          }
        );
      } else {
        setUser(null);
        setUserRole(null);
        setLoading(false);
        // Clean up role listener if exists
        unsubscribeRole();
      }
    }, (error) => {
      console.error('Auth state change error:', error);
      setUser(null);
      setUserRole(null);
      setLoading(false);
    });

    // Cleanup function
    return () => {
      unsubscribeUser();
      unsubscribeRole();
    };
  }, []);

  // Provide a method to refresh the user role manually if needed
  const refreshUserRole = async () => {
    if (user) {
      try {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setUserRole(userDoc.data().role);
        }
      } catch (error) {
        console.error('Error refreshing user role:', error);
      }
    }
  };

  const value = {
    user,
    loading,
    userRole,
    isAdmin: userRole === 'admin',
    isStudent: userRole === 'student',
    refreshUserRole
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
