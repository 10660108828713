import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';

function Links() {
  const [links, setLinks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLinks = async () => {
      try {
        const linksCollection = collection(db, 'links');
        const linksSnapshot = await getDocs(linksCollection);
        const linksList = linksSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setLinks(linksList);
      } catch (err) {
        console.error("Error fetching links: ", err);
        setError("Failed to load links. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchLinks();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-500 text-xl mt-10 px-4">
        <p>{error}</p>
        <button onClick={() => window.location.reload()} className="mt-4 bg-primary text-white px-4 py-2 rounded hover:bg-opacity-80 transition-colors">
          Try Again
        </button>
      </div>
    );
  }

  return (
    <div className="links min-h-screen py-8 px-4 sm:py-12 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-extrabold text-gray-900 text-center mb-8">Links</h2>
        {links.length === 0 ? (
          <p className="text-center text-gray-500">No links available at the moment.</p>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {links.map((link) => (
              <div key={link.id} className="bg-white rounded-lg shadow-md overflow-hidden transition duration-300 ease-in-out transform hover:scale-105">
                <img 
                  src={link.imageUrl} 
                  alt={link.title} 
                  className="w-full h-48 object-cover" 
                  loading="lazy"
                />
                <div className="p-4">
                  <h3 className="text-xl font-semibold mb-2">{link.title}</h3>
                  <p className="text-gray-600 mb-4">{link.description}</p>
                  <a
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button-primary"
                    aria-label={`Visit ${link.title}`}
                  >
                    Visit Link
                  </a>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default Links;
