import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { db } from '../firebase';
import { SiApplemusic, SiSpotify } from "react-icons/si";

function Music() {
  const [releases, setReleases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchReleases = async () => {
      try {
        const releasesCollection = collection(db, 'releases');
        const releasesQuery = query(releasesCollection, orderBy('year', 'desc'));
        const releasesSnapshot = await getDocs(releasesQuery);
        const releasesList = releasesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setReleases(releasesList);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching releases: ", err);
        setError("Failed to load releases. Please try again later.");
        setLoading(false);
      }
    };

    fetchReleases();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-500 text-xl mt-10 px-4">
        <p>{error}</p>
        <button onClick={() => window.location.reload()} className="mt-4 bg-primary text-white px-4 py-2 rounded hover:bg-opacity-80 transition-colors">
          Try Again
        </button>
      </div>
    );
  }

  return (
    <div className="music min-h-screen py-8 px-4 sm:py-12 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-extrabold text-gray-900 text-center mb-8">Music</h2>
        
        <section className="releases">
          {releases.length === 0 ? (
            <p className="text-center text-gray-500">No releases available at the moment.</p>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {releases.map((release) => (
                <div key={release.id} className="release-item bg-white rounded-lg shadow-md overflow-hidden transition duration-300 ease-in-out transform hover:scale-105">
                  <img src={release.artworkUrl} alt={`${release.name} cover`} className="w-full h-48 sm:h-64 object-cover" loading="lazy" />
                  <div className="p-4">
                    <h4 className="text-xl font-semibold text-gray-900 mb-2">{release.name}</h4>
                    <p className="text-gray-600 mb-4">{release.year}</p>
                    <div className="release-links flex flex-col space-y-3">
                      {release.spotifyUrl && (
                        <a href={release.spotifyUrl} target="_blank" rel="noopener noreferrer" className="w-full">
                          <button className="button-primary">
                            <SiSpotify size={20} className="mr-2" />
                            Spotify
                          </button>
                        </a>
                      )}
                      {release.appleMusicUrl && (
                        <a href={release.appleMusicUrl} target="_blank" rel="noopener noreferrer" className="w-full">
                          <button className="button-primary">
                            <SiApplemusic size={20} className="mr-2" />
                            Apple Music
                          </button>
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </section>
      </div>
    </div>
  );
}

export default Music;
