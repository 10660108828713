import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where, orderBy } from 'firebase/firestore';
import { db } from '../firebase';
import { GoogleMap, Marker } from '@react-google-maps/api';

const MapDisplay = ({ lat, lng }) => {
  const mapContainerStyle = {
    width: '100%',
    height: '200px'
  };

  const center = {
    lat: parseFloat(lat),
    lng: parseFloat(lng)
  };

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={15}
      center={center}
    >
      <Marker position={center} />
    </GoogleMap>
  );
};

function Events() {
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      setIsLoading(true);
      setError(null);
      const eventsRef = collection(db, 'events');
      const q = query(
        eventsRef,
        where('date', '>=', new Date().toISOString().split('T')[0]),
        orderBy('date', 'asc')
      );
      
      try {
        const querySnapshot = await getDocs(q);
        const events = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setUpcomingEvents(events);
      } catch (error) {
        console.error("Error fetching events: ", error);
        setError("Failed to fetch events. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchEvents();
  }, []);

  const formatDate = (dateString) => {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleDisplayMap = (event) => {
    setSelectedEvent(selectedEvent && selectedEvent.id === event.id ? null : event);
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-500 text-xl mt-10 px-4">
        <p>{error}</p>
        <button onClick={() => window.location.reload()} className="mt-4 bg-primary text-white px-4 py-2 rounded hover:bg-opacity-80 transition-colors">
          Try Again
        </button>
      </div>
    );
  }

  return (
    <div className="events min-h-screen py-8 px-4 sm:py-12 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <h2 className="text-3xl font-extrabold text-gray-900 text-center mb-8">Upcoming Events</h2>
        
        {upcomingEvents.length === 0 ? (
          <p className="text-center text-gray-500">No upcoming events at this time.</p>
        ) : (
          <ul className="space-y-8">
            {upcomingEvents.map((event) => (
              <li key={event.id} className="bg-white shadow-md rounded-lg overflow-hidden transition duration-300 ease-in-out transform hover:scale-105">
                <div className="p-6">
                  <h4 className="text-xl font-semibold text-gray-900 mb-2">{event.eventName}</h4>
                  <div className="text-gray-600 mb-1">{formatDate(event.date)}</div>
                  <div className="text-gray-600 mb-2">{event.time}</div>
                  <div className="text-gray-800 font-medium mb-2">{event.location}</div>
                  <p className="text-gray-700 mb-4">{event.description}</p>
                  {event.locationDetails && event.locationDetails.lat && event.locationDetails.lng && (
                    <button 
                      onClick={() => handleDisplayMap(event)} 
                      className="button-primary"
                    >
                      {selectedEvent && selectedEvent.id === event.id ? 'Hide Map' : 'Display Map'}
                    </button>
                  )}
                </div>
                {selectedEvent && selectedEvent.id === event.id && (
                  <div className="p-4 bg-gray-100">
                    <MapDisplay 
                      lat={event.locationDetails.lat} 
                      lng={event.locationDetails.lng} 
                    />
                  </div>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default Events;
