import React from 'react';
import jeff from '../assets/images/jeff.png'

function Home() {
  return (
    <div className="home container mx-auto px-4 py-8">
      <div className='flex flex-col md:flex-row md:justify-between md:items-start'>
        <div className="mb-8 md:mb-0 md:w-1/3">
          <img src={jeff} alt="Jeff Lopez" className="w-full max-w-sm mx-auto border-2 border-black bg-primary rounded-xl shadow-lg" />
        </div>
        
        <div className="md:w-2/3 md:pl-8">
          <h2 className="text-3xl font-bold mb-6 text-gray-800">About Jeff</h2>
          <div className="bio space-y-4 text-lg text-gray-700">
            <p>Jeff was born and raised in New York City. He has been playing bass, composing and arranging music for 45 years.</p>
            <p>His professional career has included performing and touring that has taken him around the globe, including a 20 year-tenure as a bass player of the U.S. Army Jazz Ambassadors. He has toured in 35 countries and all 50 states in the USA. Some performance highlights were his two appearances at New York City's legendary Carnegie Hall.</p>
            <p>Toots Thielemans, Kenny Werner, Joe Lovano, Jon Hendricks, and Phil Woods are some of the jazz luminaries he has performed alongside.</p>
            <p>Jeff has also recorded with John Scofield and Joe Lovano.</p>
            <p>His debut solo recording, The Sun and the Rain, featured Joel Frahm, Craig Handy, Tom Guarna, Rodney Holmes and Uri Caine. Jeff Lopez-Jazz Composer is comprised of musicians from the Jazz Ambassadors.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
